.delivery {
    &__title {
        font-size: 20px;
    }
    p {
        font-size: 14px;
        margin: 15px 0;
        margin-left: 5px;
    }
    a {
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: #fff;
        border-radius: 100px;
        box-shadow: var(--box--shadow);
        margin-bottom: 10px;
        text-decoration: none;
        position: relative;
        font-size: 16px;
        &::before {
            content: '';
            position: absolute;
            right: 12px;
            width: 10px;
            height: 10px;
            background-image: url('../../images/icons/arrow-black.svg');
            background-size: 50%;
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(180deg);
        }
    }
}