.product {
    min-height: 60vh;
    &__not-found {
        display: none;
        text-align: center;
    }
    &__grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px 10px;
        padding: 15px;
    } 
    &__item {
        position: relative;
        box-shadow: var(--box--shadow);
        border-radius: 25px;
        background-color: #ffffff;
        padding: 10px;
        &.visible {
            display: flex;
            animation: fadeIn 0.3s;
        }
        &.add > .product__like-btn {
            &::before {
                animation: like 0.3s;
                background-image: url("../../images/icons/heart-red.svg");
            }
        }
        p {
            display: none;
        }
    }
    &__img {
        display: block;
        min-width: 90px;
        width: 90px;
        height: 90px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 20px;
        }
    }
    &__text {
        display: block;
        padding-left: 10px;
        padding-top: 12px;
    }
    &__name {
        display: block;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 5px;
    }
    &__price {
        font-weight: 700;
        font-size: 16px;
        position: relative;
        display: inline;
        order: 2;
        &::after {
            content:' ₴';
        }
    }
    &__weight {
        color: #4b4b4b;
        font-weight: 700;
        font-size: 12px;
        position: relative;
        display: inline;
        order: 2;
        color: var(--main--color);
    }
    &__descr {
        display: block;
        font-weight: 700;
        font-size: 12px;
        margin-bottom: 10px;
        color: #4b4b4b;
    }
    &__like-btn {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    &__detail {
        max-width: var(--container--width);
        width: 100%; 
        height: 100%;
        position: fixed;
        bottom: 0;
        z-index: 1000;
        transform: translateY(100%);
        transition: transform 0.3s 0.1s, background-color 0.1s 0s;
        display: flex;
        align-items: flex-end;
        background-color: #00000000;
        &.open {
            transform: translateY(0%);
            background-color: #00000040;
            transition: transform 0.3s, background-color 0.2s 0.3s;
        }
        &__like-btn {
            position: absolute;
            top: 275px;
            right: 25px;
        }
        &-wrapper {
            position: relative;
            width: 100%;
            height: 70vh;
            position: relative;
            &::before {
                content: '+';
                position: absolute;
                right: 10px;
                top: -50px;
                width: 40px;
                height: 40px;
                background-color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000;
                z-index: 2;
                border-radius: 100px;
                box-shadow: var(--box--shadow);
                transform: rotate(45deg);
                font-size: 40px;
                font-weight: 500;
                pointer-events: none;
            }
        }
        &-content {
            width: 100%;
            height: 100%;
            padding: 20px;
            position: relative;
            background-color: #fff;
            border-radius: 30px 30px 0px 0px;
            box-shadow: var(--box--shadow);
            overflow-y: scroll;
            padding-bottom: 160px;
            -ms-overflow-style: none;
            scrollbar-width: none; 
            &::-webkit-scrollbar { 
                width: 0; 
                height: 0;
                -ms-overflow-style: none;
            }
            .product {
                &__item {
                    position: relative;
                    display: none;
                    box-shadow: none;
                    border-radius: 25px;
                    background-color: #ffffff;
                    padding: 0px;
                    width: 100%;
                    &.visible {
                        display: flex;
                        animation: none;
                        flex-direction: column;
                    }
                    &.add > .product__like-btn {
                        &::before {
                            animation: like 0.3s;
                            background-image: url("../../images/icons/heart-red.svg");
                        }
                    }
                    &.add > .like-btn_details {
                        &::before {
                            animation: like 0.3s;
                            background-image: url("../../images/icons/heart-red.svg");
                        }
                    }
                    p {
                        display: none;
                    }
                }
                &__img {
                    display: block;
                    min-width: 100%;
                    width: 100%;
                    height: 270px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                        object-fit: contain;
                    }
                }
                &__text {
                    display: block;
                    padding-left: 10px;
                    padding-top: 12px;
                }
                &__name {
                    display: block;
                    font-weight: 700;
                    font-size: 20px;
                    margin-bottom: 5px;
                }
                &__price {
                    font-weight: 700;
                    font-size: 16px;
                    position: relative;
                    display: inline;
                    order: 2;
                }
                &__weight {
                    color: #4b4b4b;
                    font-weight: 700;
                    font-size: 12px;
                    position: relative;
                    display: inline;
                    order: 2;
                    color: var(--main--color);
                }
                &__descr {
                    display: block;
                    font-weight: 700;
                    font-size: 12px;
                    margin-bottom: 10px;
                    color: #4b4b4b;
                }
                &__like-btn {
                    position: absolute;
                    bottom: 0px;
                    right: 10px;
                }
            }
        }
    }
}


.like-btn {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    &::before {
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        z-index: 2;
        background-image: url(../../images/icons/heart.svg);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        transform: translate(1);
        animation: dislike 0.3s;
    }
}

