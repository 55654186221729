.category {
    &__edit-btn {
        display: none;
    }
    &__grid {
        display: grid;
        grid-template-columns:repeat(3, 1fr);
        gap: 10px 10px;
        justify-content: center;
        text-decoration: none;
    }
    &__img {
        position: absolute;
        bottom: -10%;
        right: -10%;
        width: 65%;
        height: 65%;
        border-radius: 100px;
        z-index: 1;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
    }
    &__item {
        border-radius: 20px;
        display: flex;
        background-color: #ffffff;
        position: relative;
        text-decoration: none;
        box-shadow: var(--box--shadow);
        overflow: hidden;
        background-size: 100%;
        &::before {
            content: "";
            padding-top: 100%;
            float: left;
        }
        h2 {
            font-size: 12px;
            margin: 13px;
            pointer-events: none;
        }
    }
    &_line {
        padding: 0px;
        .category {
            &__img {
                display: none;
            }
            &__grid {
                display: block;
                width: 100%;
                overflow: hidden;
                overflow-x: scroll;
                white-space: nowrap;
                scrollbar-width: none; 
                padding-left: 10px;;
                &::-webkit-scrollbar { 
                    width: 0; 
                    height: 0;
                    -ms-overflow-style: none;
                }
            }
            &__item {
                display: inline-block;
                vertical-align: top;
                width: auto;
                position: relative;
                box-shadow: none;
                padding-bottom: 5px;
                border-radius: 0;;
                margin: 0;
                padding: 0;
                img {
                    display: none;
                }

                &::before {
                    content:"";
                    padding: 0;
                    position: absolute;
                    width: 100%;
                    margin: auto;
                    height: 3px;
                    border-radius: 100px;
                    background-color: var(--main--color);
                    bottom: 0px;
                    left: 50%;
                    transform: scaleX(0) translateX(-50%);
                    transition: 0.3s transform;
                    transform-origin: left;
                }
                &.active {
                    &::before {
                        transform: scaleX(1) translateX(-50%);
                    }
                }
                h2 {
                    font-size: 16px;
                    margin: 0px;
                    padding:5px 10px;
                    pointer-events: none;
                }
            }
        }
    }
}