.instagram {
    overflow: hidden;
    a {
        display: flex;
        align-items: center;
        text-decoration: none;
    }
    &__img {
        position: relative;
        img {
            width: 40px;
            display: block;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 100%;
            background-image: url("../../images/icons/instagram-stories.png");
            background-size: 100%;
        }
    }
    &__text {
        margin-left: 5px;
        p {
            font-size: 14px;
        }
        span {
            color: #0095F6;
            font-size: 12px;
        }
    }
    &__btn {
        width: auto;
        padding: 10px;
        border-radius: 10px;
        font-size: 12px;
        height: 100%;
        background-color: #0095F6;
        color: #fff;
        margin-left: auto;
    }
    
}