.info {
    padding: 10px 15px;
    padding-top: 20px;
    &__item {
        margin: 5px 0;
        transition: .3s all;
        overflow: hidden;
        height: 30px;
        &:last-child {
            border-bottom: none;
        }
        &.active {
            & > .info__modal {
                display: flex;
            }
        }
        a {
            text-decoration: none;
            width: 100%;
        }
    }
    
    &__header {
        color: #000000; 
        padding: 10px 5px;
        font-size: 16px;
        border: none;
        width: 100%;
        text-align: left;
        outline: none;
        display: flex;
        justify-content: left;
        align-items: center;
        height: 30px;
        text-decoration: none;
        img {
            margin-right: 10px;
            width: 20px;
        }
        &::before {
            content: "";
            width: 20px;
            height: 20px;
            background-image: url("../../images/icons/arrow-black.svg");
            background-size: 5px;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            right: 15px;
            transform: rotate(180deg);
        }
    }
}