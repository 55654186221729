@keyframes like {
    0% {
        transform: scale(0.6);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes dislike {
    0% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes fadeIn{
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes fadeInError{
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

