.accordion {
    .accordion-container {
        width: 100%;
        margin: 30px 0;
        margin-bottom: 0;
        box-shadow: var(--box--shadow);
        border-radius: 20px;
        padding: 5px;
        padding-bottom: 0;
        overflow: hidden;
        margin-top: 0;
    }
    .accordion-item {
        border-bottom: 1px solid #bababa;
        &:last-child {
            border-bottom: none;
        }
    }
    .accordion-header {
        color: #000000; 
        padding: 15px;
        font-size: 14px;
        border: none;
        width: 100%;
        text-align: left;
        outline: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
    }
    .accordion-content {
        overflow: hidden;
        padding: 0 15px;
        max-height: 0;
        transition: max-height 0.3s ease;
        font-size: 14px;
        ul {
            padding-bottom: 10px;
            list-style-type: none;
            li {
                list-style-type: none;
                &.active {
                    color: var(--main--color);
                    font-size: 16px;
                }
            }
        }
        &.active {
            max-height: 200px;
        }
    }

    .icon {
        transition: transform 0.3s ease;
    }

    .active .icon {
        transform: rotate(45deg);
    }

}