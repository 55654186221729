.liked__empty {
    margin: auto;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    flex-direction: column;
    position: relative;
    animation: fadeIn 0.3s;
    &:before {
        content:'';
        width: 80px;
        height: 5px;
        position: absolute;
        border-radius: 100px;
        background-color: var(--main--color);
        transform: rotate(-45deg) translate(3px, -2px);
    }
    span {
        position: absolute;
        text-align: center;
        bottom: -20px;
        width: 200px;
    }
    img {
        width: 60px;
    }
    &.visible {
        display: flex;
    }
}