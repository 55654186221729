.subcategory {
    margin-top: 10px;
    position: sticky;
    top: 0px;
    z-index: 8;
    background-color: #fff;
    &__grid {
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none; 
        padding-left: 10px;
        animation: fadeIn 0.3s;
        &::-webkit-scrollbar { 
            width: 0; 
            height: 0;
            -ms-overflow-style: none;
        }
    }
    &__item { 
        display: inline-flex;
        vertical-align: top;
        width: auto;
        margin: 5px;
        border-radius: 100px;
        border: 2px solid var(--main--color);
        padding: 10px 12px;
        position: relative;
        &.active {
            background-color: var(--main--color);
            h3 {
                color: rgb(255, 255, 255);
            }
        }
        h3 {
            font-size: 12px;
            pointer-events: none;
        }

    }
    &__edit-btn {
        position: absolute;
        top: -15px;
        right: -10px;
    }
    &__admin {
        .subcategory__grid {
            padding-top: 10px;
            padding-right: 50px;
        }
        .subcategory__item { 
            padding-right: 30px;
        }
    }
}
