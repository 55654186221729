.header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 50px;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
            justify-content: left;
        }
        &:last-child {
            justify-content: right;
        }
        img {
            max-width: 50px;
        }
    }
    h1 {
        font-size: 16px;
        
    }

    &__go-back {
        background-image: url(../../images/icons/arrow-black.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 8px;
        width: 30px;
        height: 30px;
        background-color: #00000000;
        border-radius: 100px;
        border: none;
    }
}