.search {
    margin-top: 10px;
    &__wrapper {
        overflow: hidden;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        &:before {
            content:"";
            text-align: center;
            font-size: 14px;
            display: flex;
            padding-left: 20px;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 20px;
            z-index: 2;
            background-image: url('../../images/icons/search.svg');
            background-size: 20px;
            background-repeat: no-repeat;
            background-position: left;
            pointer-events: none;
            transition: 0.4s transform;
            transform: translateX(calc(50% - 30px));
            left: 10px;
        }
        span {
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            font-size: 26px;
            transform: translateX(0) rotate(45deg);
            transition: 0.3s transform;
            position: absolute;
            right: -35px;
            background-color: #f4f4f4;
            border-radius: 100%;
            font-weight: 500;
        }
        &.active {
            &:before {
                transform: translateX(0%);
                color: transparent;
            }
            & > .search__input {
                width: calc(100% - 40px);
            }
            & > span {
                transform: translateX(-35px) rotate(45deg);
            }
        }
    }
    &__input {
        width: 100%;
        transition: 0.3s all;
        transform-origin: left;
        border-radius: 100px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        input {
            width: 100%;
            height: 35px;
            border: none;
            background-color: #f4f4f4;
            outline: none;
            font-size: 16px;
            padding: 0px 10px;
            position: relative;
            z-index: 1;
            padding-left: 33px;
            transition: 0.3s all;
            transform-origin: left;
            &:active, :hover, :focus {
                outline: 0;
                outline-offset: 0;
            }
        }

    }

}