.error {
    max-width: calc(var(--container--width) - 20px);
    width: calc(100% - 20px);
    position: fixed;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #b00000;
    color: #fff;
    z-index: 20;
    padding: 20px 10px;
    font-size: 12px;
    border-radius: 5px;
    animation: fadeInError 5s forwards;
    pointer-events: none;
}