
.skeletron-line-category {
    display: inline-block;
    width: 80px;
    height: 27.5px;
    margin-right: 10px;
    border-radius: 100px;

  }
  
  .skeletron-category {
        aspect-ratio: 1/1;
        border-radius: 20px;
        overflow: hidden;
  }
  
  .skeletron-subcategory {
    display: inline-block;
    width: 120px;
    height: 37.5px;
    margin: 10px 5px;
    margin-bottom: 0;
    border-radius: 100px;

  }
  
  .skeletron-product {
    width: 100%;
    height: 110px;
    border-radius: 20px;
  }

  .skeletron-banner {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    aspect-ratio: 4 / 2.5;
    &__img {
      position: absolute;
      width: calc(100% - 30px);
      height: calc(100% - 40px);
      object-fit: cover;
      object-position: center;
      border-radius: 25px;
    }
  }

  
@keyframes fullView {
    100% {
      width: 100%;
    }
  }
  .animate {
    animation: shimmer 3s;
    animation-iteration-count: infinite;
    background: linear-gradient(to right, #f6f6f6 5%, #eeeeee 25%, #f6f6f6 35%);
    background-size: 1000px 100%;
  }
  @keyframes shimmer {
    from {
      background-position: -1000px 0;
    }
    to {
      background-position: 1000px 0;
    }
  }
  