.title {
    font-size: 16px;
    margin-bottom: 20px;
    position: relative;
    &::before {
        content: '';
        width: 40px;
        height: 3px;
        border-radius: 100px;
        background-color: var(--main--color);
        position: absolute;
        bottom: -4px;
        left: 0;
    }
}