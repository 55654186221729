.burger {
    &__wrapper {
        position: fixed;
        z-index: 998;
        width: 100%;
        overflow: hidden;
        top: 0;
        width: 100%;
        max-width: var(--container--width);
        height: 100%;
        pointer-events: none;
        background-color: transparent;
        z-index: 9;
        &.active > .burger__content {
            transform: translateX(0%);
        }
        &.active {
            background-color: #00000045;
            pointer-events: auto;
        }
    }
    &__content {
        width: 78%;
        height: 100%;
        background-color:rgb(255, 255, 255);
        transform: translateX(-100%);
        transition: 0.3s transform ease-in-out;
        pointer-events:all;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        ul {
            padding-top: 40px;
            width: 100%;
            text-align: center;
            list-style-type: none;
            li {
                margin: 30px 0;
                font-size: 20px;
                list-style-type: none;
                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    justify-content: center;
                    img {
                        width: 24px;
                        margin-right: 10px;
                    }
                }
            }
        }
        p {
            font-size: 12px;
            color: #656565;
        }
    }
    &__contacts {
        width: 100%;
        padding: 15px;
        border-radius: 30px;
        text-align: center;
        background-color: #f8f8f8; 
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            margin: 10px 0;
            img {
                width: 24px;
                margin-right: 10px;
            }
        }
    }
}


.burger-btn {
    width: 45px;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 330ms ease-out;
    margin: 5px 5px;
    position: relative;
    z-index: 10;
    background-color: #ffffff;
    padding: 15px 10px;
    border-radius: 100px; 
    border: none;
    &__line {
        background-color: #000000;
        border-radius: 100px;
        width: 100%;
        height: 3px;
        &_start {
            width: 50%;
            transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
            transform-origin: right;
        }
        &_end {
            width: 50%;
            align-self: flex-end;
            transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
            transform-origin: left;
        }
    }
    &.active {
        transform: rotate(-45deg);  
        .burger-btn__line {
            &_start {
                transform: rotate(-90deg) translateX(7px);
            }
            &_end {
                transform: rotate(-90deg) translateX(-7px);
            }
        }
    }
}
