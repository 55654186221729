.nav {
    position: fixed;
    max-width: calc(var(--container--width) - 20px);
    height: 70px;
    width: calc(100% - 20px);
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(255, 255, 255);
    box-shadow: var(--box--shadow);
    border-radius: 15px;
    z-index: 999;
    display: flex;
    align-items: center;
    padding: 5px;
    ul {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        width: 100%;
        li {
            list-style-type: none;
            width: 100%;
            a {
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                font-size: 10px;
                transition: 0.3s;
                position: relative;
                img {
                    width: 25px;
                    margin-bottom: 3px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 4px;
                    border-radius: 100px;
                    background-color: var(--main--color);
                    left: 50%;
                    bottom: 15px;
                    transform: translateY(15px) translateX(-50%);
                    transition: .3s all;
                    opacity: 0;
                }
                &.active {
                    transition: 0.1s;
                    transform: translateY(-3px);
                    color: #00000000;
                    &::before {
                        transform: translateY(7px) translateX(-50%);
                        opacity: 1;
                    }
                }
            }
        }
    }
    &__liked-counter {
        width: 20px;
        height: 20px;
        background-color: var(--main--color);
        position: absolute;
        right: 10%;
        top: -5px;
        border-radius: 100px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: #000; 
    }
    &-admin {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background-color: transparent;
        box-shadow: none;
        z-index: 900;
        &__hole {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center; 
            border-radius: 15px;
            &::before {
                content:"";
                position: absolute;
                width: 75px;
                height: 75px;
                background-color: transparent;
                border-radius: 100px;
                box-shadow: 0px 0px 0px 2000px var(--main--color);
                pointer-events: none;
                transform: translateY(calc(-50% + 7px));
            }
        }
        &__add-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            box-shadow: var(--box--shadow);
            border-radius: 100px;
            position: absolute;
            background-color: #fff;
            bottom: 50%; 
            font-size: 30px;
            font-weight: 500;
            z-index: 900;
            transition: 0.3s transform;
            &.open {
                transform: rotate(-45deg);
            }
            &-wrapper {
                max-width: var(--container--width);
                width: 100%;
                position: fixed;
                height: 100vh;
                bottom: 0;
                left: 50%;
                z-index: 899;
                transform: translate(-50%, 100%);
                background-color: #00000000;
                transition: 0.3s transform 0.1s, 0.1s background-color 0s;
                &.open {
                    transform: translate(-50%, 0%);
                    background-color: rgba(0,0,0,.5);
                    transition: 0.3s transform, 0.3s background-color 0.3s;

                }
            }
            &-content {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 70vh;
                background-color: #fff;
                box-shadow: var(--box--shadow);
                border-radius: 30px 30px 0px 0px;
                padding: 30px;
                &::before {
                    content: "+";
                    position: absolute;
                    right: 10px;
                    top: -50px;
                    width: 40px;
                    height: 40px;
                    background-color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #000;
                    z-index: 2;
                    border-radius: 100px;
                    box-shadow: var(--box--shadow);
                    transform: rotate(45deg);
                    font-size: 40px;
                    font-weight: 500;
                    pointer-events: none;
                }
                ul {
                    list-style: none;
                    li {
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        list-style: none;
                        button {
                            display: flex;
                            background-color: #00000000;
                            align-items: center;
                            border: none;
                            font-size: 16px;
                            margin: 10px;
                            width: 100%;
                        }
                        img {
                            width: 40px;
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
        &__btns {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            &_left {
                display: flex;
                width: 50%;
                justify-content: left;
                position: relative;
            }
            &_right {
                display: flex;
                width: 50%;
                justify-content: right;
                position: relative;

            }
        }
        &__btn {
            width: 40px;
            height: 40px;
            margin: 10px;
            padding: 7px;
            position: relative;
            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
}